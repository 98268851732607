<template>
    <v-card>
        <v-line :content="ip"></v-line>
    </v-card>
</template>

<script setup lang="ts">
const ip = ref('');

const { data, pending } = useFetch('/api/ip', {
    server: false,
});

watch(pending, (value) => {
    if (!value) {
        ip.value = data.value?.ip || '';
    }
}, { immediate: true });
</script>
