<template>
    <!-- eslint-disable vuejs-accessibility/form-control-has-label -->
    <!-- eslint-disable eslint-disable-next-line tailwindcss/no-custom-classname -->
    <input v-model.number="currentValue"
        type="range"
        :min="min"
        :max="max"
        class="slider h-8 w-full appearance-none overflow-hidden rounded
            border-2 border-green-500 bg-gray-900/75 outline-none backdrop-blur-sm
            hover:bg-green-900 focus:bg-green-900">
</template>

<script lang="ts" setup>
const properties = defineProps<{
    modelValue: number
    min: number
    max: number
}>();

const emit = defineEmits<{
    (event: 'update:modelValue', value: number): void
}>();

const currentValue = useVModel(properties, 'modelValue', emit);
</script>

<style lang="scss" scoped>
.slider::-webkit-slider-thumb {
    appearance: none;
    background: #48bb78;
    height: 2rem;
    width: 1rem;
}

.slider::-moz-range-thumb {
    appearance: none;
    background: #48bb78;
    border: 0;
    border-radius: 0;
    height: 2rem;
    width: 1rem;
}
</style>
