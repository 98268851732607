import { init, browserTracingIntegration } from '@sentry/browser';

export default defineNuxtPlugin(() => {
    const {
        public: {
            sentryDsn,
        },
    } = useRuntimeConfig();

    if (sentryDsn) {
        init({
            dsn: sentryDsn,
            environment: process.env.SENTRY_ENVIRONMENT,
            integrations: [
                browserTracingIntegration(),
            ],
            sampleRate: 1,
            tracesSampleRate: 1,
        });
    }
});
