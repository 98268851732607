<template>
    <v-card>
        <template #header>
            <v-number class="w-20">{{ types[type] }}</v-number>

            <v-slider v-model.number="type"
                :min="0"
                :max="types.length - 1"></v-slider>

            <v-button @click="generateChars">
                <font-awesome-icon icon="redo" />
            </v-button>
        </template>

        <v-line v-for="(char, i) in chars"
            :key="i"
            :number="i"
            :content="char"></v-line>
    </v-card>
</template>

<script setup lang="ts">
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import cryptoRandomString from 'crypto-random-string';

useIcons([faRedo]);

const chars = ref<string[]>(Array.from<string>({ length: 30 }).fill(' '));
const types = ref([
    8,
    12,
    16,
    24,
    32,
    48,
    64,
    128,
    256,
]);

const type = useLocalStorage('char-card-type', 2);
const lineCount = ref(30);

function generateChars(): void {
    const result: string[] = [];

    for (let x = 0; x < lineCount.value; x += 1) {
        result.push(cryptoRandomString({
            length: types.value[type.value],
            type: 'url-safe',
        }));
    }

    chars.value = result;
}

onMounted(() => {
    generateChars();
});

watchDebounced(type, () => {
    generateChars();
}, { debounce: 300 });
</script>
