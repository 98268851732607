<template>
    <button type="button"
        class="min-w-full px-5 text-left font-mono hover:bg-green-900
            focus:bg-green-900 focus:outline-none"
        :class="{
            'text-red-500': isHighlighted,
            'text-green-500': isUpdated,
        }"
        @click="() => copy()">
        <span class="whitespace-pre"
            :class="{
                'animate-pulse opacity-25': displayContent === '...',
            }">{{ displayContent }}</span>
    </button>
</template>

<script setup lang="ts">
const properties = withDefaults(defineProps<{
    content: string
    number?: number
}>(), {
    number: 0,
});

const isHighlighted = refAutoReset(false, 300);
const isUpdated = refAutoReset(false, 50);
const displayContent = ref('...');

const { copy, copied } = useClipboard({ source: displayContent });

watch(copied, (value) => {
    if (value) {
        isHighlighted.value = true;
    }
});

watch(() => properties.content, (value) => {
    setTimeout(() => {
        isUpdated.value = true;
        displayContent.value = value;
    }, 50 * properties.number);
});
</script>
