<template>
    <v-card>
        <template #header>
            <v-spacer></v-spacer>

            <v-button @click="generateWords">
                <font-awesome-icon icon="redo" />
            </v-button>
        </template>

        <v-line v-for="(word, i) in words"
            :key="i"
            :number="i"
            :content="word"></v-line>
    </v-card>
</template>

<script setup lang="ts">
import { faRedo } from '@fortawesome/free-solid-svg-icons';

const words = ref<string[]>(Array.from<string>({ length: 30 }).fill(' '));
const lineCount = ref(30);
const wordList = ref<string[]>([]);

useIcons([faRedo]);

function getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min))) + Math.ceil(min);
}

function leetify(input: string): string {
    const output = [...input.replaceAll(/(\s|-)/g, '')];

    const replace: Record<string, string> = {
        a: '4',
        e: '3',
        s: 'z',
        o: '0',
        t: '+',
        l: '1',
        ph: 'f',
        y: 'j',
        i: '!',
    };

    output.forEach((char, index) => {
        if (replace[char] !== undefined && getRandomInt(0, 3) === 0) {
            output[index] = replace[char];
        }

        if (getRandomInt(0, 3) === 0) {
            output[index] = char.toUpperCase();
        }
    });

    return output.join('');
}

function generateWords(): void {
    const result: string[] = [];

    for (let index = 0; index < lineCount.value; index += 1) {
        result.push(wordList.value[Math.floor(Math.random() * wordList.value.length)]);
    }

    words.value = result.map((word) => leetify(word));
}

const { data, pending } = useLazyFetch<string>('/words.txt');

watch(pending, (value) => {
    if (!value) {
        wordList.value = data.value?.split('\n') || [];

        generateWords();
    }
}, { immediate: true });
</script>
