<template>
    <div class="relative overflow-y-auto rounded border-2 border-green-500">
        <div v-if="!!$slots.header"
            class="sticky left-0 top-0 flex items-center space-x-5 p-5 pb-0">
            <slot name="header"></slot>
        </div>

        <div class="overflow-x-auto py-5">
            <slot></slot>
        </div>
    </div>
</template>
