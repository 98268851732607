<template>
    <v-card>
        <template #header>
            <v-number class="w-20">{{ typeTitle }}</v-number>

            <v-slider v-model.number="type"
                :min="0"
                :max="types.length - 1"></v-slider>

            <v-button @click="generateHashes">
                <font-awesome-icon icon="redo" />
            </v-button>
        </template>

        <v-line v-for="(hash, i) in hashes"
            :key="i"
            :number="i"
            :content="hash"></v-line>
    </v-card>
</template>

<script setup lang="ts">
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import md5 from 'crypto-js/md5';
import sha256 from 'crypto-js/sha256';
import cryptoRandomString from 'crypto-random-string';

useIcons([faRedo]);

const hashes = ref<string[]>(Array.from<string>({ length: 30 }).fill(' '));
const types = ref([
    'md5',
    'sha',
    'b64',
]);

const type = useLocalStorage('hash-card-type', 0);
const typeTitle = computed(() => types.value[type.value]);
const lineCount = ref(30);

function generateHashes(): void {
    const result: string[] = [];
    const chosenType = types.value[type.value];

    for (let index = 0; index < lineCount.value; index += 1) {
        const randomString = cryptoRandomString({
            length: 32,
            type: 'url-safe',
        });

        let string = '';
        switch (chosenType) {
            case 'md5': {
                string = md5(randomString).toString();
                break;
            }
            case 'sha': {
                string = sha256(randomString).toString();
                break;
            }
            case 'b64': {
                string = btoa(randomString);
                break;
            }
            default:
        }

        result.push(string);
    }

    hashes.value = result;
}

watchDebounced(type, () => {
    generateHashes();
}, { debounce: 300 });

onMounted(() => {
    generateHashes();
});
</script>
