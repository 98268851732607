<template>
    <v-card>
        <template #header>
            <v-number class="w-20">{{ wordCount }}</v-number>

            <v-slider v-model.number="wordCount"
                :min="2"
                :max="10"></v-slider>

            <v-button @click="generatePhrases">
                <font-awesome-icon icon="redo" />
            </v-button>
        </template>

        <v-line v-for="(phrase, i) in phrases"
            :key="i"
            :number="i"
            :content="phrase"></v-line>
    </v-card>
</template>

<script setup lang="ts">
import { faRedo } from '@fortawesome/free-solid-svg-icons';

useIcons([faRedo]);

const phrases = ref<string[]>(Array.from<string>({ length: 30 }).fill(' '));
const wordCount = useLocalStorage('phrase-card-word-count', 3);
const lineCount = ref(30);
const wordList = ref<string[]>([]);

function generatePhrases(): void {
    const result: string[] = [];
    const wordListCount = wordList.value.length;

    for (let x = 0; x < lineCount.value; x += 1) {
        const word: string[] = [];

        for (let index = 0; index < wordCount.value; index += 1) {
            word.push(wordList.value[Math.floor(Math.random() * wordListCount)]);
        }

        result.push(word.map((w) => (
            w.replace(/^-/, '')
                .replace(/-$/, '')
        )).join('-').toLowerCase());
    }

    phrases.value = result;
}

watchDebounced(wordCount, () => {
    generatePhrases();
}, { debounce: 300 });

const { data, pending } = useLazyFetch<string>('/words.txt');

watch(pending, (value) => {
    if (!value) {
        wordList.value = data.value?.split('\n') || [];

        generatePhrases();
    }
}, { immediate: true });
</script>
